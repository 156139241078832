import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <h2>{`Work Item Property Widget`}</h2>
      <p>{`The `}<strong parentName="p">{`Work Item Property Widget`}</strong>{` displays a card showing the property selected on your dashboards. You select the work item and which property to display.`}</p>
      <p>{`There are times when you have a parent or central work item that contains information that you want displayed on multiple dashboards. Instead of adding static content to each dashboard, this widget allows you to display information from the work item and then maintain the information in one location.`}</p>
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "790px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "32.98611111111111%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA5klEQVQY05WN0W6CMBhG66UhPvSWjKfwiZZoAVGphkACLbTOLUhsu6x//0VwZDcz2cm5+G5OPjJ/ngdhELz8GN5chIvZ02z5uoQr9Nfee5/nH8fjRQhf18D5XbLKV0yxrM0O6sAk27d7ptg4zv3ZaOOcQ8Sy7KOoSZI2jps0VVEkttsT0ReNiAiIfnAaHicAvFJ+s1GUVpTWcSzS9JRl70Rrba01v7ADXwPWWgDnHEgJSSLX64pSPp7vdm8E/8YY03WdH5ASyvKzKExR6KpyQiDn/lEMAOMYYyGwaW4KMfqfmPMpu8ffYg53o71LflwAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "detailed cards",
          "title": "detailed cards",
          "src": "/static/4833b530b0aaf2646a6af18b3db92a2f/ccd65/detailed-cards.png",
          "srcSet": ["/static/4833b530b0aaf2646a6af18b3db92a2f/7fc1e/detailed-cards.png 288w", "/static/4833b530b0aaf2646a6af18b3db92a2f/a5df1/detailed-cards.png 576w", "/static/4833b530b0aaf2646a6af18b3db92a2f/ccd65/detailed-cards.png 790w"],
          "sizes": "(max-width: 790px) 100vw, 790px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>
    </PageDescription>
    <h2>{`Use Cases`}</h2>
    <h3>{`Project Overview / Product Vision`}</h3>
    <p>{`You have a work item that contains an overview of the project. This might be a product vision that is shared across multiple teams and/or multiple projects. Display the project overview or product vision on many dashboards, while still only maintaining the content in the single work item.`}</p>
    <h3>{`Project Status`}</h3>
    <p>{`You have a Project work item that contains current status for a status report. Instead of maintaining the status separately, maintain it in the Project work item and display the status on the dashboard with other key information, such as burndown, so it’s available for everyone on the team to view.`}</p>
    <h3>{`Fill Title with Status Color`}</h3>
    <p>{`Your widget displays the progress for the project and you also have a progress-color property. Instead of have a separate widget displaying the progress-color, show it with the progress. After selecting the work item id and propery field then also select progress-color property for the Title Status Color. The background color of the Title is set to the specified property. Only the background of the title is set and the remainder of the widget is unaffected.`}</p>
    <h3>{`(New) Target Date`}</h3>
    <p>{`In the latest version of this widget, you can now specify the date format to display a date field. This is typically used when you want to display your Target Date or Go-Live Date on the dashboard. It uses the standard javascript formats, such as ‘MMM DD YYYY’. The date format option only applies if the selected property is a date.`}</p>
    <h2>{`Quick steps to get started`}</h2>
    <h3>{`Method 1`}</h3>
    <ul>
      <li parentName="ul">{`Edit your dashboard`}</li>
      <li parentName="ul">{`Select the ‘Work Item Property’ widget`}</li>
      <li parentName="ul">{`Click Add to add the widget to your dashboard`}</li>
      <li parentName="ul">{`Click Configure on the widget`}</li>
    </ul>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "720px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "45.833333333333336%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABwUlEQVQoz42S3YrbMBCF/dKFvkcvetEXaGlvFpYGCgu7lMI2LNT1Lo5/E8eO7bUjyZYsx3Zs5RQr21y0veiBjxnBaDgzknH95ho0o5illMLpdLowq+s6FGWBLE/RHtpL3R+olzumEdgeZCMvhdM06TiOI/q+xziMGMSElg6Q9QGTmvAPqdO5sWk4my2klBdXlDFwykAJge8FCGmGr+wWd+ILLL5CEFcIUgJ/R+Ale427LVXKOjzzo2m8XdgoKMdJTaCUgRCinTFG4bkBfiYBFvEHXG3e4b5Y4sEucPfDwzdrg++rHZZOins7UfZOYE0G03j/9IhSCO2bc46iKHReVRUc18U6IaiKBjznKEkPJ29gxxWcTCCiR82WjSqiAyLam4a1vIWo2V9LmZt7ngc/reCkDF4+xxp+2WNNR4TkiGDfa6ydVA9Rg6e0NY3tzRUaUqKquR53hrHz6HEco2ACGZXIaIOcNih5d0YM2DfH36jjqGYfprFJM9QvblarlcZxHN3M9300guPQSrSyAa9rfRa8hmh7yG4Ab3v9yp8eOV59zk1jG0XgvEYYhnBd9zym7yNJEp3P//A/pD5aNV4vns1f/hCiMc1gVAgAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "add widget",
        "title": "add widget",
        "src": "/static/78deb0b72f51bec7e8e4be0ce6b6c621/b85f6/add-widget-steps.png",
        "srcSet": ["/static/78deb0b72f51bec7e8e4be0ce6b6c621/7fc1e/add-widget-steps.png 288w", "/static/78deb0b72f51bec7e8e4be0ce6b6c621/a5df1/add-widget-steps.png 576w", "/static/78deb0b72f51bec7e8e4be0ce6b6c621/b85f6/add-widget-steps.png 720w"],
        "sizes": "(max-width: 720px) 100vw, 720px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <ul>
      <li parentName="ul">{`Enter a Work Item Id and tab out of the text box.`}</li>
      <li parentName="ul">{`Enter a Title to display (at the top of the widget) and tab out of the text box.`}</li>
      <li parentName="ul">{`Select a Property Name and tab out of the dropdown.`}</li>
      <li parentName="ul">{`(Optional) Select a Title Status Color Property Name (background color for the Title) and tab out of the dropdown. For example, this could be the status color for the project.`}</li>
      <li parentName="ul">{`(Optional) Enter a Color to set the background color for the entire widget and tab out of the text box.`}</li>
      <li parentName="ul">{`(Optional) Enter a Date Format to format the widget context if it’s a date and tab out of the text box. For example, use MMM DD YYYY to format the Target Go Live property.`}</li>
      <li parentName="ul">{`Click on Save`}</li>
    </ul>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "444px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "166.31944444444446%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAhCAYAAADZPosTAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACCklEQVRIx62Wy27CMBBF8/+fVZBYsEBFouqWRVF5v0IgflbHyRQHQkhoLd3adezrmTszNom1Vud57k+nk1NKeZBlmTfGeJpz7g7xfDl2rHfOfSWQjcdj1+v1/GAw8MPh0Pf7fT+dTlsTGmPc4XDg/2VSHhD+QG6tDQuljzc+OsAVjX6RsHG73eKyb2rH49FvNpsKedRksiC8XC4e67TWd0BTvp3P57Cubo3WOngXLERMWcj4FszzHQ/qvpdwrLHWLpJbPbD4dgNzdfMxYRnlwmVOJ1UApr/QrhrCvNvtPGEHkNJuo/wE1yizGSIInqRGO8K6NPgzITlGlLCU8YNca6chm0nsNE0D2X6/92XEXrdwtVr9BiN2uUNgqkFp9KUrIe7hLrkI0FF63I+j33BotZbRcL1eh+InJ6lbIW2pp9xUhYbL5dLP5/PQE5iuLrOsUsut8uKJhXcuo5eUH1aKhoKmgyuEjDEXyJ0nIJWYo3+SQv/rMp8rQYk31RG1IK9qSLqgG1oyBoxJIdEQTaVEaw4SzsJCzBXEGgJ5U+K+8/X1gp5Vwn/A1WVKS0oOrdBJIHXciZBN6CJPZoym3JOXsBy7cnzvcsu8q72xhdB2hdbaZllm8zy3SimbpqkhI5xz38krjzBXG7/OJFdHo5GfzWbhAUXDd2PMhzFm0hZa64lSKoBxOfdpjHn7AbXRJVQjvksGAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "add wi",
        "title": "add wi",
        "src": "/static/e6eaf0b01d0c618103f3fd165a71e677/c3c3f/wi-property-configuration.png",
        "srcSet": ["/static/e6eaf0b01d0c618103f3fd165a71e677/7fc1e/wi-property-configuration.png 288w", "/static/e6eaf0b01d0c618103f3fd165a71e677/c3c3f/wi-property-configuration.png 444w"],
        "sizes": "(max-width: 444px) 100vw, 444px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <h3>{`Method 2`}</h3>
    <ul>
      <li parentName="ul">{`Select the context menu ”…” on any work item list, work item dialog, or board`}</li>
      <li parentName="ul">{`Select the item ‘Add Property Widget to dashboard’`}</li>
      <li parentName="ul">{`Select the desired dashboard`}</li>
    </ul>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "405px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "125.34722222222221%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAYAAAAxFw7TAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADuUlEQVQ4y3VVyW7jRhTUp+V7cso9/5BjDjkHOiYIchtAh5ziLAMbmbFsy6YtcW9uzSab+1JBtURFETwPeGg21ayueptWeMfyPEccx8iyDE3TmJX7KIpQliVc1zVnqqrCNE1o23aw3BiWE65X8zzj2pMkQRAE8DzPfEgg27ZhWRbSND2vBO/7Hlrr4e7pgNuttV61bYtr77oO4zhCKWXApJRnJxDZ8vnCBhJReb5eURKdQH3Xomvb856SyKIoCsOUXte1uYy+KCIgpUspj4Bt06BuOrh5h6zqoVQOz/cNkwWQYaDzEtoF2BkwJ0PKYxxo3QwM/HUYoHVl5BGE0umUyQQxDFwX4AUwy7L1ijvK2+122L+9wrYPeHt7M+/InoBkSXCCku0ST76/ZCiEOGaZ8YjjBKE43swPybKuKh4ye74nCOOotTbSF/kLYBAER4bcYGop+iwpiSOEYYg4Tc9JISAZk8Bil5IN4GT2Mz78IfD9Lwlc18Htwyv+ebYReT6gciRp+r8yua7bdwF//k3gh18DBN4e9w9P+Pz4DMdxIYVArpSJIWP6BRsYIs/z/pM89jXmsTYdwq7wYgnbD035MFYsIcaSRsn8ZvF5ngde5rruEXAcB9RNi67vLw5NBmgpGTrjyA8p8xJwmqaBl5yTwkOPD494fDz6drs1A6DtOpPRyxrk2aVTFicga9n3/ZPkeYJuSpRKmdo6ycDM7AMm6ybzSQLHccx61S0mKWfAYeyhuhpJoZFlqalBJkrXDXJVoqlrc9FlH18z5DemsMFbOAOf75F//gsiSQ0L464HEScolDKsGMPFTCIN4HQCHE+tNx2L1P3uGxy+/gpWpLC1HDztnuEGAlXPsSSRS8nxBCkz4+NJBYzkeZjNtMnWq2kcMUwzlG2hePkEISIc9nt4gYCbKMRliyiVCKIUUZqbZ65l3aHuRlTtwHXQTQ8Rp+sVaTM2SV4g0/U5ETRbCDwEGe5eInzc+fi4C3D7InBzf8DN1sbfOx+/f97j5t4e7qwID/vTcGApROwIKY99qxSaqsbO2ePOfkWSFQgTCV8khp1uBpRND90O5lk3w8BnEWdHQI4qdgKDzloz/xeqwM628OnwhKosocsSZVmgqat3W4/xNBN7mqaxLMvR9/0xDEPjQgjzLo2TMYvTMc/zcTyOGOPzPF97exqwP64YP3bDwoySl3mnihxt30HK/PznxN/fMyqVUv7Ewt58yXVdbWQuN0EQboIg2DiOs4njeFNV1bV/0Fr/GYbht/8CN8xvq8sjM/oAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "add to dashboard",
        "title": "add to dashboard",
        "src": "/static/70be200b62469924dd9a37cb269d1d67/233e0/add-dashboard.png",
        "srcSet": ["/static/70be200b62469924dd9a37cb269d1d67/7fc1e/add-dashboard.png 288w", "/static/70be200b62469924dd9a37cb269d1d67/233e0/add-dashboard.png 405w"],
        "sizes": "(max-width: 405px) 100vw, 405px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      